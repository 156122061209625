import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import Layout from "./components/common/Layout";
import Home from "./components/home/Home";

function App() {
  return (
    <Router>
      <Layout>
        <Route exact path="/">
          <Home />
        </Route>
      </Layout>
    </Router>
  );
}

export default App;
