import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import facebookImg from "../../assets/images/logos/facebook.svg";
import instagramImg from "../../assets/images/logos/instagram.svg";
import tiktokImg from "../../assets/images/logos/tiktok.svg";
import linkedInImg from "../../assets/images/logos/linkedin.svg";
//import { Link } from "react-router-dom";

export default function Footer({
  brand,
  brandText,
  branches,
  branchesText,
  socialMedia,
  copyright,
}) {
  return (
    <Row className="m-0 p-0">
      <Col className="footerCustomStyleTop">
        <Row className="footerCustomStyleBottom text-center">
          <Col xs={12} lg={4}>
            <Row className="m-0 p-0 mt-5">
              <Col>
                <h3>{brand}</h3>
              </Col>
            </Row>
            <Row className="m-0 p-0 mt-2">
              <Col>{brandText}</Col>
            </Row>
            <Row className="m-0 p-0 mt-5">
              <Col>
                <a
                  href={socialMedia.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image width="35px" src={instagramImg} alt="instagram" />
                </a>
                <a
                  href={socialMedia.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image width="15px" src={facebookImg} alt="facebook" />
                </a>
                <a
                  href={socialMedia.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image width="32px" src={tiktokImg} alt="tiktok" />
                </a>
                <a
                  href={socialMedia.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image
                    width="33px"
                    src={linkedInImg}
                    alt="linkedin"
                    className="mb-1"
                  />
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <Row className="m-0 p-0 mt-5">
              <Col className="mt-5">
                lunes a viernes de 8:00 a 18:00hs y sabados de 8:30 a 12:30hs
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <Row className="m-0 p-0 mt-5 text-white">
              <Col>
                <h3>{branches}</h3>
              </Col>
            </Row>
            {/* <Row className="m-0 p-0 mt-2 text-white">
              <Col>{branchesText}</Col>
            </Row> */}

            <Row
              className="p-0 text-center"
              style={{ margin: "20px 0 60px 0" }}
            >
              <Col style={{ fontSize: "15px" }}>{copyright}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
