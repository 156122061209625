import React from "react";
import { Button, Card, Row, Col } from "react-bootstrap";

import photoCard2 from "../../assets/images/promoCards/paqcorolla.jpg";
import photoCard1 from "../../assets/images/promoCards/paqetios.jpg";
import photoCard3 from "../../assets/images/promoCards/paqhilux.jpg";
import photoCard4 from "../../assets/images/promoCards/paqsw4.jpg";
import photoCard5 from "../../assets/images/promoCards/paqyaris.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PromoCards() {
  const cardContent = [
    {
      image: photoCard1,
      title: "Etios",
      promo: "Aceite y Filtro",
      /* text: "Modelos entre 2013 al 2017.", */
    },
    {
      image: photoCard5,
      title: "Yaris",
      promo: "Neumáticos",
      /* text: "Modelos entre 2013 al 2017.", */
    },
    {
      image: photoCard2,
      title: "Corolla",
      promo: "Pastillas de freno",
      /* text: "Modelos entre 2013 al 2017.", */
    },

    {
      image: photoCard3,
      title: "Hilux",
      promo: "Suspensión",
      /* text: "Modelos entre 2013 al 2017.", */
    },
    {
      image: photoCard4,
      title: "SW4",
      promo: "Batería",
      /* text: "Modelos entre 2013 al 2017.", */
    },
  ];

  return (
    <Row className="m-0 p-0 justify-content-center row-cols-1 row-cols-md-2 row-cols-xl-5">
      {cardContent.map((content, i) => (
        <Col className="my-2" key={i}>
          <Card style={{ border: "0px" }}>
            <Card.Img
              variant="top"
              src={content.image}
              style={{ borderRadius: "5px" }}
            />
            <Card.Body
              className="bg-white m-3 shadow text-center"
              style={{
                borderRadius: "5px",
                position: "relative",
                top: "-60px",
              }}
            >
              <Card.Title style={{ color: "#d50000" }}>
                {content.title}
              </Card.Title>
              <Card.Text>
                <Row>
                  <Col style={{ fontSize: "19px" }}>
                    <b>{content.promo}</b>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: "15px" }}>{content.text}</Col>
                </Row>
              </Card.Text>
              <a
                href={`https://wa.me/5491145058787?text=Hola, me gustaria adquirir ${content.promo.toLowerCase()} para el modelo ${
                  content.title
                } :%20`}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="outline-danger">
                  <FontAwesomeIcon
                    icon="fa-brands fa-whatsapp"
                    className="mr-2"
                  />
                  <b>Agendar Turno</b>
                </Button>
              </a>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
