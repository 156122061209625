import React from "react";
import { Button, Col, Row } from "react-bootstrap";

import PromoCards from "../common/PromoCards";
//import Formulario from "../Formulario";

import CarouselSecundario from "../common/CarouselSecundario";
import { carouselSecundarioImagenes } from "../data/CarouselSecundarioData";
import CarouselPrincipal from "../common/CarouselPrincipal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import hiluxNoBgnd from "../../assets/images/formulario/hilux-removebg-preview.png";

import car from "../../assets/images/iconos/car.png";
import hand from "../../assets/images/iconos/hand.png";
import tools from "../../assets/images/iconos/tools.png";

export default function Home() {
  //window.scrollTo(0, 0);

  return (
    <>
      <Row className="m-0 mb-4 p-0 shadow my-2">
        <Col xs={12} lg={12} className="text-center smNoPadding m-0 p-0">
          <CarouselPrincipal />
        </Col>
      </Row>
      <Row className="m-0 p-0 pt-4">
        <Col
          xs={12}
          lg={{ span: "10", offset: "1" }}
          className="text-center mt-2 mb-4"
        >
          <h3>
            <b>Nuestras promociones</b>
          </h3>
          <p style={{ color: "#757575", fontSize: "18px" }}>
            Las mejores promociones para su vehículo. <br />
            Trabajamos con{" "}
            <b style={{ color: "#d50000" }}>repuestos originales</b> y contamos
            con mecánicos especializados.
          </p>
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col
          xs={12}
          lg={{ span: "10", offset: "1" }}
          className="text-center mt-2 mb-4"
        >
          <h5>
            <b>
              Atendemos todas la marcas y realizamos los siguientes servicios de
              mantenimiento:
            </b>
          </h5>
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col xs={12} lg={{ span: "10", offset: "1" }}>
          <PromoCards />
        </Col>
      </Row>
      <Row className="m-0 p-0">
        {/* <Col xs={12} lg={8} style={{ backgroundColor: "#EFEFEF" }}>
          <Row className="m-0 mb-4 p-0">
            <Col xs={12} className="text-center">
              <Formulario />
            </Col>
          </Row>
        </Col> */}
        <Col
          xs={12}
          lg={{ offset: 4, span: 4 }}
          style={{ backgroundColor: "#969190" }}
        >
          <Row className="m-0 mb-2 p-0">
            <Col xs={12} className="text-center">
              <h4 className="text-center text-white mt-4">
                <i>
                  <span style={{ fontFamily: "HelveticaNowLight" }}>
                    Taller especializado
                  </span>{" "}
                  EN TU TOYOTA CON MAS DE 5 AÑOS DE USO!
                </i>
              </h4>
            </Col>
          </Row>
          <Row className="m-0 mb-4 mt-5 p-0">
            <Col xs={12} lg={12} className="text-center">
              <img
                src={hiluxNoBgnd}
                alt="hilux"
                style={{ position: "relative", left: "-100px" }}
                width="100%"
                className="hiluxsizeOnMobile"
              />
            </Col>
          </Row>
          <Row className="m-0 mb-3 mt-5">
            <Col xs={12} lg={12}>
              <span
                style={{ fontSize: "28px", color: "white" }}
                className="pr-2"
              >
                <i>
                  <b>QUEDATE AHÍ:</b>
                </i>
              </span>
            </Col>
          </Row>
          <Row className="m-0 mb-5">
            <Col xs={12} lg={12}>
              <a
                href="https://www.instagram.com/tservice.prana/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#AA040E" }}
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-instagram"
                  className=" pl-2 pr-2"
                  style={{ fontSize: "35px" }}
                />
              </a>

              <a
                href={`https://wa.me/5491145058787?text=Hola, me gustaria agendar un turno al taller:%20`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "#AA040E" }}
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-whatsapp"
                  style={{ fontSize: "35px" }}
                  className="pr-2"
                />
              </a>

              <a
                href={`https://www.facebook.com/tservice.prana/`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "#AA040E" }}
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-facebook"
                  style={{ fontSize: "35px" }}
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className="m-0 mb-4 p-0 text-center"
        style={{ backgroundColor: "#e0e0e0" }}
      >
        <Col xs={12} className="my-5">
          <h3>
            <b>¿Necesita realizar un service a su vehículo?</b>
          </h3>
          <p style={{ color: "#757575", fontSize: "18px" }}>
            Único Taller especializado en vehículos Toyota con más de 5 años de
            uso.
          </p>

          <CarouselSecundario images={carouselSecundarioImagenes} />

          <Row className="m-0 p-0 mt-4">
            <Col>
              <a
                href={`https://wa.me/5491145058787?text=Hola, me gustaria agendar un turno al taller:%20`}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="outline-danger">
                  <FontAwesomeIcon
                    icon="fa-brands fa-whatsapp"
                    className="mr-2"
                  />
                  <b>Agendar Turno</b>
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-4">
        <Col className="text-center">
          <h3>
            <b>Ventajas de TService</b>
          </h3>
          <p style={{ color: "#757575", fontSize: "18px" }}>
            Taller de mantenimiento
          </p>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col
          xs={{ span: 10, offset: 1 }}
          lg={{ span: 8, offset: 2 }}
          className="text-center"
        >
          <Row className="m-0 p-0">
            <Col
              xs={12}
              lg={4}
              style={{
                fontSize: "20px",
                border: "2px dashed #616161",
                borderRadius: "190px",
              }}
              className="pt-5"
            >
              Bajo costo de <br />
              mantenimiento
              <img src={hand} alt="hand" width="80%" />
            </Col>
            <Col
              xs={12}
              lg={4}
              style={{
                fontSize: "20px",
                border: "2px dashed #616161",
                borderRadius: "190px",
              }}
              className="pt-5"
            >
              Fuerza de trabajo <br /> competitiva
              <img src={tools} alt="tools" width="80%" />
            </Col>
            <Col
              xs={12}
              lg={4}
              style={{
                fontSize: "20px",
                border: "2px dashed #616161",
                borderRadius: "190px",
              }}
              className="pt-5 pb-4"
            >
              Especialistas en <br />
              vehículos Toyota
              <img
                src={car}
                alt="car"
                style={{ marginTop: "15%" }}
                width="80%"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
