import React from "react"; //imr
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap/";

import SideBar from "./SideBar";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

import { socialMedia } from "../../constants/constants";
import { DateTime } from "luxon";

import tsLogo from "../../assets/images/logoTservice.png";

export default function Layout({ children }) {
  return (
    <>
      <Container className="m-0 p-0" fluid>
        <Row className="m-0 p-0">
          <Col className="m-0 p-0">
            <NavigationBar />
            {children}
          </Col>
        </Row>
        <Footer
          brand={<img src={tsLogo} alt="logo" width="250px" />}
          /* brandText="Con mas de 50 años en la industria automotriz, damos un nuevo paso para acercarnos a nuestros clientes y brindar nuevos servicios." */
          copyright={`© ${DateTime.now().toFormat(
            "yyyy"
          )} Copyright Auto Prana S.A.`}
          branches={
            <Row className="m-0 p-0">
              <Col className="text-center">
                <iframe
                  title="mapa"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.8303431580825!2d-58.493051323325425!3d-34.5831591564143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb643ef4b5479%3A0x6d58dd32e22bff90!2sAv.%20de%20los%20Constituyentes%204301%2C%20C1431%20Villa%20Urquiza%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1686604863311!5m2!1sen!2sar"
                  width="150"
                  height="150"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </Col>
            </Row>
          }
          /* branchesText="Hace click para ver nuestros puntos de venta." */
          socialMedia={socialMedia}
        />
      </Container>
      <SideBar />
    </>
  );
}
