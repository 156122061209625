import React from "react";

import { Carousel } from "react-bootstrap";

import carousel_1 from "../../assets/images/carousel/1.jpg";
import carousel_2 from "../../assets/images/carousel/2.jpg";
import carousel_3 from "../../assets/images/carousel/3.jpg";
import carousel_4 from "../../assets/images/carousel/4.jpg";

export default function CarouselPrincipal() {
  return (
    <Carousel fade className="carouselHeight">
      <Carousel.Item>
        <img
          className="d-block w-100 carouselItemHeight"
          src={carousel_1}
          alt="First slide"
        />
        <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.7)" }}>
          <h3>Nuevo punto de servicio </h3>
          <p>AV. DE LOS CONSTITUYENTES 4301, CABA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carouselItemHeight"
          src={carousel_2}
          alt="Second slide"
        />

        <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.7)" }}>
          <h3>Servicio mecánico </h3>
          <p>PARA TODO TIPO DE VEHÍCULOS</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carouselItemHeight"
          src={carousel_3}
          alt="Third slide"
        />

        <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.7)" }}>
          <h3>Especialistas en el cuidado</h3>
          <p>DE TU TOYOTA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carouselItemHeight"
          src={carousel_4}
          alt="Third slide"
        />

        <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.7)" }}>
          <h3>El servicio que tu Toyota</h3>
          <p>ESTABA ESPERANDO</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
