import React, { useState, useEffect } from "react";

import ItemsCarousel from "react-items-carousel";

import CarouselSecundarioCards from "./CarouselSecundarioCards";

export default function Carousel({ images }) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [slides, setSlides] = useState(images);
  const [cantTarjetas, setCantTarjetas] = useState(3);

  const size = useWindowSize();

  useEffect(() => {
    setSlides(images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, images);

  function useWindowSize() {
    const isClient = typeof window === "object";

    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize(getSize());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return windowSize;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (size.width < 450) {
      setCantTarjetas(1);
    } else {
      if (size.width < 700) {
        setCantTarjetas(1);
      } else {
        if (size.width < 800) {
          setCantTarjetas(1);
        } else {
          if (size.width < 1000) {
            setCantTarjetas(1);
          } else {
            setCantTarjetas(3);
          }
        }
      }
    }
  });

  const sliderModelo = () => {
    var carousel = slides.map((slide, i) => {
      return (
        <CarouselSecundarioCards
          activeItemIndex={activeItemIndex}
          slide={slide}
          i={i}
        />
      );
    });

    return carousel;
  };

  const [timer, setTimer] = useState(0);
  const [timer2, setTimer2] = useState(0);

  const handleCard = (futureIndex) => {
    window.clearInterval(timer);
    setTimer(0);

    if (futureIndex > slides.length - 1) {
      setActiveItemIndex(0);
    } else if (futureIndex < 0) {
      setActiveItemIndex(slides.length - 1);
    } else {
      setActiveItemIndex(futureIndex);
    }

    window.clearTimeout(timer2);
    setTimer2(window.setTimeout(changeCard, 6000));
  };

  const changeCard = () => {
    setTimer(
      window.setInterval(() => {
        setActiveItemIndex((state) => {
          if (state < slides.length - 1) {
            return state + 1;
          } else {
            return 0;
          }
        });
      }, 6000)
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeCard(), []);

  return (
    <div>
      <ItemsCarousel
        infiniteLoop={true}
        activePosition={"center"}
        disableSwipe={false}
        alwaysShowChevrons={false}
        slidesToScroll={1}
        showSlither={false}
        firstAndLastGutter={false}
        requestToChangeActive={handleCard}
        activeItemIndex={activeItemIndex}
        numberOfCards={cantTarjetas}
        gutter={30}
        leftChevron={false}
        rightChevron={false}
      >
        {sliderModelo()}
      </ItemsCarousel>
    </div>
  );
}
