import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  ProSidebar,
  Menu,
  //MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { Row, Col /* Image */, Image } from "react-bootstrap/";

import { uiContext } from "../../contexts/UiContextProvider";

//Breakpoints
import { HideAt } from "react-with-breakpoints";

import tsLogo from "../../assets/images/logoTservice.png";

//import { NavLink } from "react-router-dom";

export default function SideBar() {
  const uiManager = useContext(uiContext);

  let height = window.innerHeight;
  let footerHeight = (32.8 * height) / 100;

  return (
    <ProSidebar
      collapsed={uiManager.sideBar.collapsed}
      breakPoint={"md"}
      toggled={uiManager.sideBar.toggled}
      onToggle={uiManager.sideBar.hide}
      rtl={true}
    >
      <SidebarHeader
        style={{
          color: "black",
          borderLeft: "1px solid lightGrey",
        }}
      >
        <Row className="m-0 p-0">
          <Col
            xs={1}
            style={{ paddingTop: "35px", color: "black", height: "50px" }}
            onClick={uiManager.sideBar.hide}
          >
            <FontAwesomeIcon icon="times-circle" className="shake" />
          </Col>
          <Col xs={10}>
            <Image width="200px" src={tsLogo} />
          </Col>
        </Row>
      </SidebarHeader>

      <SidebarContent
        style={{
          borderLeft: "1px solid lightGrey",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Row className="m-0 p-0 mt-4">
          <Col className="text-left">
            <p>
              <b>
                TALLER ESPECIALIZADO PARA VEHÍCULOS TOYOTA CON MÁS DE 5 AÑOS DE
                USO
              </b>
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col className="text-left">
            <p>El servicio que tu Toyota estaba esperando</p>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col className="text-left">
            <p>
              AV. DE LOS CONSTITUYENTES 4301, C1431EXI, CABA Buenos Aires,
              Argentina <br />
              114505-8787
            </p>
          </Col>
        </Row>

        <Row className="m-0 p-0 mt-4">
          <Col
            className="text-left"
            style={{
              color: "#AA040E",
            }}
          >
            <a
              href={`https://www.facebook.com/tservice.prana/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#AA040E" }}
            >
              <FontAwesomeIcon
                icon="fa-brands fa-facebook"
                style={{ fontSize: "30px" }}
              />
            </a>

            <a
              href={`https://wa.me/5491145058787?text=Hola, me gustaria agendar un turno al taller:%20`}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#AA040E" }}
            >
              <FontAwesomeIcon
                icon="fa-brands fa-whatsapp"
                style={{ fontSize: "30px" }}
                className="ml-1 mr-3"
              />
            </a>

            <a
              href="https://www.instagram.com/tservice.prana/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#AA040E" }}
            >
              <FontAwesomeIcon
                icon="fa-brands fa-instagram"
                className="mr-3"
                style={{ fontSize: "30px" }}
              />
            </a>

            <a
              href="https://www.tiktok.com/@tserviceprana"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#AA040E" }}
            >
              <FontAwesomeIcon
                icon="fa-brands fa-tiktok"
                className="mr-3"
                style={{ fontSize: "30px" }}
              />
            </a>
          </Col>
        </Row>

        <hr />

        <Row className="m-0 p-0">
          <Col className="text-center">
            <iframe
              title="mapa"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.8303431580825!2d-58.493051323325425!3d-34.5831591564143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb643ef4b5479%3A0x6d58dd32e22bff90!2sAv.%20de%20los%20Constituyentes%204301%2C%20C1431%20Villa%20Urquiza%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1686604863311!5m2!1sen!2sar"
              width="150"
              height="150"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </Col>
        </Row>

        <Menu>
          {/*     <MenuItem>
            <a
              href="https://store.autoprana.com.ar/vtf/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              0km
            </a>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/usados"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Usados
            </NavLink>
          </MenuItem> */}

          {/*  <MenuItem
            icon={
              <Image
                width="25px"
                src="https://www.autoprana.com.ar/images/logos/kinto-mobility-service.svg"
              />
            }
          >
            <NavLink
              to="/kinto"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              KINTO
            </NavLink>
          </MenuItem>
          <MenuItem
            icon={
              <Image src="https://www.autoprana.com.ar/images/logos/clubToyota.png" />
            }
          >
            <a
              href="https://www.clubtoyota.com.ar/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Club Toyota
            </a>
          </MenuItem> */}
        </Menu>
        <HideAt breakpoint="mediumAndAbove"></HideAt>
      </SidebarContent>
      <SidebarFooter
        className="dynamicFooter"
        style={{ backgroundColor: "#616161 ", height: footerHeight }}
      ></SidebarFooter>
    </ProSidebar>
  );
}
