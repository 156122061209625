export const socialMedia = {
  facebook: "https://www.facebook.com/tservice.prana/",
  instagram: "https://www.instagram.com/tservice.prana/",
  youtube: "",
  linkedin: "https://ar.linkedin.com/company/toyota-prana",
  tiktok: "https://www.tiktok.com/@tserviceprana",
};

export const imgPath = "https://store.autoprana.com.ar/img/imgDefault/";
export const imagPathUsados = "https://store.autoprana.com.ar/img/";

export const capitalize = (text) =>
  text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

export const truncate = (text, length) =>
  text && text.length > length ? `${text.substring(0, length)}` : text;
