import carousel_sec_1 from "../../assets/images/iconos/servicios-color-01.png";
import carousel_sec_2 from "../../assets/images/iconos/servicios-color-02.png";
import carousel_sec_3 from "../../assets/images/iconos/servicios-color-03.png";
import carousel_sec_4 from "../../assets/images/iconos/servicios-color-04.png";
import carousel_sec_5 from "../../assets/images/iconos/servicios-color-05.png";

export const carouselSecundarioImagenes = [
  {
    img: carousel_sec_1,
    link: "",
  },
  {
    img: carousel_sec_2,
    link: "",
  },
  {
    img: carousel_sec_3,
    link: "",
  },
  {
    img: carousel_sec_4,
    link: "",
  },
  {
    img: carousel_sec_5,
    link: "",
  },
];
